import { DateTime } from 'luxon';
import { roundNumber } from '@/helpers/roundNumber';
import { ApexOptions } from 'apexcharts';

export const defaultChartOptions = (isLoading: boolean = false): ApexOptions =>
  Object.freeze({
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false }
    },
    grid: {
      show: !isLoading
    },
    noData: {
      text: isLoading ? 'Loading data...' : 'No data available',
      offsetY: -8,
      style: {
        color: 'rgba(58, 53, 65, 0.3)',
        fontSize: '20px',
        fontFamily: 'Inter',
        fontWeight: 500
      }
    },
    xaxis: {
      labels: { show: false },
      tickPlacement: 'on'
    },
    yaxis: { labels: { show: false } }
  });

export const commonChartOptions = (): ApexOptions =>
  Object.freeze({
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false }
    },
    dataLabels: { enabled: false },
    grid: {
      show: true,
      position: 'back',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      tickPlacement: 'on',
      labels: {
        show: true,
        rotate: 0,
        style: {
          colors: '#15191F',
          fontSize: '12px',
          fontWeight: '400'
        },
        formatter: xAxisFormatter
      },
      axisBorder: { show: false },
      tooltip: { enabled: false }
    },
    yaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: {
        style: {
          colors: '#15191F',
          fontSize: '12px',
          fontWeight: '400'
        },
        formatter: yAxisFormatter
      }
    },
    tooltip: {
      shared: false,
      intersect: true
    },
    noData: {
      text: 'No data available',
      style: {
        color: 'rgba(58, 53, 65, 0.3)',
        fontSize: '24px',
        fontFamily: 'Inter'
      }
    }
  });

export const customTooltip = (
  w: { config: ApexOptions | any },
  seriesIndex: number,
  dataPointIndex: number,
  isUsdFormat: boolean = false
): string => {
  const data = w.config.series[seriesIndex].data[dataPointIndex];

  if (!data || !data.x || typeof data.y === 'undefined') {
    return '<div class="arrow_box"><span>Data not available</span></div>';
  }

  const date = DateTime.fromISO(data.x).toFormat('d MMMM yyyy');
  let value = data.y;

  if (isUsdFormat) {
    value = value.toFixed(2) + ' USD';
  }

  return `<div class="custom-tooltip"><span>${date}: <b>${value}</b></span></div>`;
};

export const xAxisFormatter = (value: string): string => {
  return DateTime.fromJSDate(new Date(value)).toFormat('dd LLL');
};

export const yAxisFormatter = (v: number): string => {
  return roundNumber(v, 6, 3);
};
