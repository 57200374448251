





































import { PropType } from 'vue';
import { mapState, mapGetters } from 'vuex';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import VueApexCharts from 'vue-apexcharts';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import {
  commonChartOptions,
  customTooltip,
  defaultChartOptions
} from './CopyStakeChartConfigHelper';
import { getCopyStakeChartData } from '@/api/CopyStake';
import {
  SelectItem,
  DateRangeParams,
  ECopyStakeDashboardChartType,
  ICopyStakeDashboardChartDataItem,
  ICopyStakeChartDataParam,
  TCopyStakeDashboardChartDataResponse
} from '@/api/schema';
import { ApexOptions } from 'apexcharts';
import { merge } from 'lodash';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';

interface IChartConfigItem {
  key: keyof typeof ECopyStakeDashboardChartType;
  title?: string;
  titleTooltip?: string;
  series: IChartSeriesItem[];
  chartOptions: () => ApexOptions;
}

interface IChartSeriesItem {
  name: string;
  data: { x: string; y: number }[];
}

export default {
  name: 'CopyStakeDashboardGraphs',
  components: {
    VExtendedDataPicker,
    VueApexCharts,
    Tooltip
  },
  data: (): any => ({
    selectedChart: ECopyStakeDashboardChartType.DAU,
    chartData: {
      [ECopyStakeDashboardChartType.DAU]: [],
      [ECopyStakeDashboardChartType.BET_VOLUME]: [],
      [ECopyStakeDashboardChartType.GGR]: []
    },
    isLoading: false
  }),
  props: {
    dates: {
      type: Object as PropType<DateRangeParams>,
      required: true,
      default: (): DateRangeParams => {
        return {} as DateRangeParams;
      }
    }
  },
  computed: {
    ...mapState('FeatureFlags', ['features']),
    ...mapGetters('Onboarding', ['operatorId']),
    selectData(): SelectItem[] {
      return [
        { value: ECopyStakeDashboardChartType.DAU, text: 'DAU' },
        {
          value: ECopyStakeDashboardChartType.BET_VOLUME,
          text: 'Bet Volume'
        },
        { value: ECopyStakeDashboardChartType.GGR, text: 'GGR' }
      ];
    },
    getChartOptions(): ApexOptions {
      if (!this.currentChart?.series?.length || this.isLoading)
        return { ...defaultChartOptions(this.isLoading) };

      return { ...this.currentChart.chartOptions() };
    },
    currentChart(): IChartConfigItem {
      const commonOptions = commonChartOptions();

      const configs = {
        [ECopyStakeDashboardChartType.DAU]: {
          key: ECopyStakeDashboardChartType.DAU,
          title: 'CopyStake DAU',
          titleTooltip: 'Players with at least one BET copied',
          series: this.createSeries(
            this.chartData[ECopyStakeDashboardChartType.DAU],
            'DAU'
          ),
          chartOptions: () => {
            return merge({}, commonOptions, {
              chart: {
                type: 'bar'
              },
              plotOptions: {
                bar: { borderRadius: 6 }
              },
              fill: {
                type: 'solid',
                opacity: 1
              },
              colors: ['#8AE2F8'],
              tooltip: {
                custom: ({ seriesIndex, dataPointIndex, w }) =>
                  customTooltip(w, seriesIndex, dataPointIndex)
              }
            });
          }
        },
        [ECopyStakeDashboardChartType.BET_VOLUME]: {
          key: ECopyStakeDashboardChartType.BET_VOLUME,
          title: 'CopyStake Bet Volume',
          titleTooltip: 'The total volume of bets placed on the CopyStake',
          series: this.createSeries(
            this.chartData[ECopyStakeDashboardChartType.BET_VOLUME],
            'Bet Volume'
          ),
          chartOptions: () =>
            merge({}, commonOptions, {
              chart: {
                type: 'area'
              },
              stroke: {
                curve: 'smooth',
                width: 1,
                colors: ['#C254F5']
              },
              fill: {
                type: 'gradient',
                gradient: {
                  type: 'vertical',
                  shadeIntensity: 1,
                  gradientToColors: ['#C254F5'],
                  stops: [30, 85],
                  opacityFrom: 0.85,
                  opacityTo: 0.3
                }
              },
              markers: {
                size: 4,
                strokeWidth: 1,
                hover: {
                  sizeOffset: 2
                }
              },
              grid: {
                xaxis: {
                  lines: {
                    show: true
                  }
                }
              },
              colors: ['#C254F5'],
              tooltip: {
                custom: ({ seriesIndex, dataPointIndex, w }) =>
                  customTooltip(w, seriesIndex, dataPointIndex, true)
              }
            })
        },
        [ECopyStakeDashboardChartType.GGR]: {
          key: ECopyStakeDashboardChartType.GGR,
          title: 'CopyStake GGR',
          titleTooltip: 'Gross Gaming Revenue for selected period',
          series: this.createSeries(
            this.chartData[ECopyStakeDashboardChartType.GGR],
            'GGR'
          ),
          chartOptions: () =>
            merge({}, commonOptions, {
              chart: {
                type: 'area'
              },
              stroke: {
                curve: 'smooth',
                width: 1,
                colors: ['#2D9CDB']
              },
              fill: {
                type: 'gradient',
                gradient: {
                  type: 'vertical',
                  shadeIntensity: 1,
                  gradientToColors: ['#2D9CDB'],
                  stops: [30, 85],
                  opacityFrom: 0.85,
                  opacityTo: 0.3
                }
              },
              markers: {
                size: 4,
                strokeWidth: 1,
                hover: {
                  sizeOffset: 2
                }
              },
              grid: {
                xaxis: {
                  lines: {
                    show: true
                  }
                }
              },
              colors: ['#2D9CDB'],
              tooltip: {
                custom: ({ seriesIndex, dataPointIndex, w }) =>
                  customTooltip(w, seriesIndex, dataPointIndex, true)
              }
            })
        }
      };

      return configs[this.selectedChart] as IChartConfigItem;
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getData();
        }
      },
      immediate: true
    },
    selectedChart: 'getData',
    dates: 'getData'
  },
  methods: {
    async getData(): Promise<void> {
      this.isLoading = true;

      const params: ICopyStakeChartDataParam = {
        ...this.dates,
        type: this.selectedChart
      };

      await getCopyStakeChartData(params)
        .then((data: TCopyStakeDashboardChartDataResponse) => {
          this.chartData[this.selectedChart] = data;
        })
        .catch((err: AxiosError) => {
          this.chartData[this.selectedChart] = [];
          errorToastMessage(err);
        })
        .finally((): void => {
          this.isLoading = false;
        });
    },
    createSeries(
      data: ICopyStakeDashboardChartDataItem[],
      name: string
    ): IChartSeriesItem[] {
      return data?.length
        ? [
            {
              name,
              data: data.map((item: ICopyStakeDashboardChartDataItem) => {
                return {
                  x: item.date,
                  y: item.value
                };
              })
            }
          ]
        : [];
    }
  }
};
